<template>
  <div class="guide-page">
    <div class="bg bg-1">
      <img src="~@/assets/images/backups/bg1.png" width="100%" />
      <a href="//www.jianguoyun.com/" target="_self">www.jianguoyun.com</a>
    </div>
    <div class="bg bg-2">
      <img src="~@/assets/images/backups/bg2.png" width="100%" />
    </div>
    <div class="bg bg-3">
      <img src="~@/assets/images/backups/bg3.png" v-if="!isXiaoZhang" width="100%" />
      <img
        v-if="isXiaoZhang"
        src="https://download-cos.yofish.com/ad-gongjushiyebu/20220627155301201-wecom-temp-33dc5ca4ccee5105994d5fc2db3ab24c.png"
        width="100%" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'BackupsGuide',
  data() {
    return {
    };
  },
  computed: {
    isXiaoZhang() {
      const hostUrl = window.location.host;
      return hostUrl.includes('housejizhang.com');
    },
  },
  mounted() {
    document.title = '授权账号密码获取';
  },
};
</script>
<style lang="scss" scoped>
.guide-page {
  background-color: rgb(244, 245, 246);
  .bg {
    position: relative;
    a {
      position: absolute;
      font-size: 30px;
      color: rgb(219, 104, 100);
      bottom: 60px;
      z-index: 20;
      left: 18%;
    }
  }
}
</style>
